$(document).on('change', '#browser-settings-selector', function (_event) {
  let inheritWebdrivers = $('option:selected', this).val()
  let parentOptions = $(this).data('parent-options')
  let childOptions = $(this).data('child-options')
  let entryId = $(this).data('entry-id')

  if (inheritWebdrivers === 'true') {
    $(`input[id*="${formType($(this))}-${entryId}-webdriver-ids"]`).each((_i, option) => {
      $(option).prop('checked', false)
    })
    modifyOptions(parentOptions, entryId, formType($(this)), true)
    disableCheckboxes(formType($(this)), entryId)
  } else {
    modifyOptions(parentOptions, entryId, formType($(this)), false)
    modifyOptions(childOptions, entryId, formType($(this)), true)
    enableCheckboxes(formType($(this)), entryId)
  }
});

// To keep chrome option selected if nothing else is selected
$(document).on('change', '.webdriver-checkboxes', function (_event) {
  let options = $(this).find('.driver-option')
  let optionSelected = $.makeArray(options).some(function (option) { return option.checked })
  if (!optionSelected) {
    $(this).find('.driver-option.webdriver-option-1').prop('checked', true)
  }
})

// To disable checkbox but be able to pass default selection of disabled
// checkboxes to controller
$(document).on('click', '.disabled-checkbox', function (_event) {
  return false;
})

const formType = (selector) => {
  if (selector.hasClass('edit')) {
    return 'edit'
  } else if (selector.hasClass('new')) {
    return 'new'
  } else if (selector.hasClass('reschedule')) {
    return 'reschedule'
  } else {
    return 'schedule'
  }
}

function modifyOptions(options, entryId, formType, valueToSet) {
  options.forEach((option_id) => {
    let element = $(`#${formType}-${entryId}-webdriver-ids-${option_id}`)
    element.prop('checked', valueToSet)
  })
}

function disableCheckboxes(type, entryId) {
  $(`.${type}-${entryId}-webdriver-checkboxes`).find('.driver-option').each((_index, option) => {
    $(option).addClass('disabled-checkbox')
    $(option).parent().addClass('disabled-box')
  })
}

function enableCheckboxes(type, entryId) {
  $(`.${type}-${entryId}-webdriver-checkboxes`).find('.driver-option').each((_index, option) => {
    $(option).removeClass('disabled-checkbox')
    $(option).parent().removeClass('disabled-box')
  })
}

export {
  formType,
  modifyOptions,
  disableCheckboxes,
  enableCheckboxes
}
