$(document).on('click', '.password-toggle-icon', function (_event) {
  let passwordShown = $(this).attr('class').includes('fa-eye-slash')
  let passwordDisplayed = $(this).attr('class').includes('fa-eye')
  if (passwordShown) {
    $(this).removeClass('fa-eye-slash').addClass('fa-eye')
    $(this.closest('.input-group')).find('input')[0].type = 'text'
  } else if (passwordDisplayed) {
    $(this).removeClass('fa-eye').addClass('fa-eye-slash')
    $(this.closest('.input-group')).find('input')[0].type = 'password'
  }
});
