// It's a HACK!
// https://github.com/actmd/abraham/issues/31

//= require js-cookie/src/js.cookie
//= require shepherd.js/dist/js/shepherd

import Shepherd from 'shepherd.js';
window.Shepherd = Shepherd;
import Cookies from 'js-cookie/src/js.cookie';
window.Cookies = Cookies;

let Abraham = new Object();
window.Abraham = Abraham;

Abraham.tours = {};
Abraham.incompleteTours = [];

Abraham.startTour = function(tourName) {
  if (!Shepherd.activeTour && Abraham.tours['intro'] && gon.show_intro_tour) {
    Abraham.tours[tourName].start();
  }
};

Abraham.startNextIncompleteTour = function() {
  if (Abraham.incompleteTours.length && Abraham.incompleteTours.length > 0) {
    Abraham.tours[Abraham.incompleteTours[0]].checkAndStart();
  }
};

document.addEventListener('DOMContentLoaded', Abraham.startNextIncompleteTour);
document.addEventListener('turbolinks:load', Abraham.startNextIncompleteTour);

document.addEventListener('turbolinks:before-cache', function() {
  // Remove visible product tours
  document.querySelectorAll('.shepherd-element').forEach(function(el) { el.remove() });
  // Clear Abraham data
  Abraham.tours = {};
  Abraham.incompleteTours = [];
});

$(document).on('click', '.skip-tour', function(event) {
  event.stopPropagation();
  event.stopImmediatePropagation();

  $.ajax({
    url: '/team',
    beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    type: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ allowed_to_show_tours: false })
  });
});
